
import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from 'gatsby'

import Navbar from './navbar'

import styled from "@emotion/styled"


const Header = ({alternative}) => {

  const data = useStaticQuery(graphql`
    query MenuLinksQuery {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
        }
      }
    }
  `)

  const [isOpaque, setOpaque] = useState(false);

  const handleScroll = () => {
      setOpaque(window.scrollY > 0);
  }

  useEffect(() => {

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    }

  }, []);

  return(
      <StyledHeader isOpaque={isOpaque} alternative={alternative}>
        <div className="logo-container container">
          <Navbar logoAlt={alternative} menuLinks={data.site.siteMetadata.menuLinks} />
        </div>
      </StyledHeader>
  )

}

export default Header

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem 0;
  z-index: 100;
  background-color: ${props => props.isOpaque ? "#8DC63F" : "" };
  color: ${props=> props.alternative && !props.isOpaque ? '#FFF' : ''};
  fill: ${props=> props.alternative && !props.isOpaque ? '#FFF' : '#484349'};
  transition: all 0.4s ease-in-out;
`