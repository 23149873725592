/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({
  children,
  footerGraphicEnabled,
  footerSeparatorEnabled,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header  />
        <main>{children}</main>
        <Footer
          graphicEnabled={footerGraphicEnabled}
          separatorEnabled={footerSeparatorEnabled}
        />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footerGraphicEnabled: PropTypes.bool,
  footerSeparatorEnabled: PropTypes.bool,
}

Layout.defaultProps = {
  footerGraphicEnabled: true,
  footerSeparatorEnabled: true,
}

export default Layout
