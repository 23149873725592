import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const ColorBarContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : "white")};
  color: ${props => (props.textColor ? props.textColor : "white")};
  padding: 24px 16px;
  text-align: center;
`

const Separator = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : "white")};
  filter: brightness(125%);
  height: 16px;
  width: 100%;
`

const ColorBar = ({ bgColor, children, separatorEnabled, textColor }) => {
  return (
    <>
      {separatorEnabled ? <Separator bgColor={bgColor} /> : null}

      <ColorBarContainer textColor={textColor} bgColor={bgColor}>
        {children}
      </ColorBarContainer>
    </>
  )
}

ColorBar.propTypes = {
  separatorEnabled: PropTypes.bool,
}

ColorBar.defaultProps = {
  separatorEnabled: true,
}

export default ColorBar
