// Logo.js

import React from "react"
import styled from '@emotion/styled'
import { Link } from 'gatsby';


const Logo = () => {

  const url = typeof window !== 'undefined' ? window.location.href.split('/').pop() : '';
  
  return (
    <Link to="/" style={{textDecoration: "none", borderBottom: "none", display: "block" }}>
      <StyledLogo xmlns="http://www.w3.org/2000/StyledLogo" width="166" height="58.466" viewBox="0 0 166 58.466">
          <g id="Group_477" data-name="Group 477" transform="translate(552.777 -26.211)">
            <text id="Northern_Arizona_Technology_Alliance" data-name="Northern Arizona Technology Alliance" transform="translate(-469.777 81.677)" fill="inherit" style={{fontSize: "8.75", fontFamily: "SegoeUI, Segoe UI", letterSpacing: "0.06em"}} ><tspan x="-82.046" y="0">Northern Arizona Technology Alliance</tspan></text>
            <g id="Group_476" data-name="Group 476" transform="translate(-546.777 26.211)">
              <path id="Path_1234" data-name="Path 1234" d="M-518.151,26.407h-.763a4.838,4.838,0,0,0-4.824,4.824V47.687l-12.95-19.121a5.609,5.609,0,0,0-5.132-2.334h-.133a4.838,4.838,0,0,0-4.824,4.824v33.82a4.838,4.838,0,0,0,4.824,4.824h.763a4.838,4.838,0,0,0,4.824-4.824V48.041l12.986,19.173a5.19,5.19,0,0,0,1.508,1.46,4.79,4.79,0,0,0,2.958,1.026h.763a4.838,4.838,0,0,0,4.824-4.824V31.231A4.838,4.838,0,0,0-518.151,26.407Z" transform="translate(546.777 -26.211)" fill="inherit"/>
              <path id="Path_1235" data-name="Path 1235" d="M-290.207,27.025h-24.4a4.838,4.838,0,0,0-4.824,4.824v.013a4.838,4.838,0,0,0,4.824,4.824h7.371V65.408a4.838,4.838,0,0,0,4.824,4.824h.013a4.838,4.838,0,0,0,4.824-4.824V36.686h7.371a4.838,4.838,0,0,0,4.824-4.824v-.013A4.838,4.838,0,0,0-290.207,27.025Z" transform="translate(398.466 -26.742)" fill="inherit"/>
              <path id="Path_1236" data-name="Path 1236" d="M-406.784,28.765a5.418,5.418,0,0,0-7.449-1.7l-.012.008a5.419,5.419,0,0,0-1.7,7.449L-395.408,67.2a5.418,5.418,0,0,0,7.449,1.7l.013-.008a5.419,5.419,0,0,0,1.7-7.449Z" transform="translate(461.964 -26.231)" fill="inherit"/>
              <ellipse id="Ellipse_33" data-name="Ellipse 33" cx="5.445" cy="5.445" rx="5.445" ry="5.445" transform="translate(39.49 31.96) rotate(-67.5)" fill="inherit"/>
              <path id="Path_1237" data-name="Path 1237" d="M-163.431,61.444l-20.538-32.679a5.418,5.418,0,0,0-7.449-1.7l-.013.008a5.419,5.419,0,0,0-1.7,7.449L-172.592,67.2a5.418,5.418,0,0,0,7.449,1.7l.013-.008A5.419,5.419,0,0,0-163.431,61.444Z" transform="translate(316.607 -26.231)" fill="inherit"/>
              <circle id="Ellipse_34" data-name="Ellipse 34" cx="5.445" cy="5.445" r="5.445" transform="translate(116.949 26.066) rotate(-22.5)" fill="inherit"/>
            </g>
          </g>
        </StyledLogo>
      </Link>
  )
  
}

export default Logo
    
    
const StyledLogo = styled.svg`
  fill: inherit;
  visibility: ${({url}) => (url !== 'about' ? 'visible' : 'hidden')};

  @media (max-width: 760px) {
    fill: #fff;
  }
`