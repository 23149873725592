// Navbar.js

import React, { useState } from "react"

import { Link } from 'gatsby'
import Logo from "./logo"
import styled from '@emotion/styled'

const Navigation = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 20px 0;

  @media (max-width: 768px) {
    display: flex;
  }
`

const Navbox = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 0;
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 400px;
    justify-content: space-between;
  }
`


const NavItem = styled.li`
  text-transform: capitalize;
  padding: 0 10px;
  text-decoration: none;
  underline: none;
  color: inherit;
`

const Hamburger = styled.div`
  background-color: ${props => props.open ? '#111' : '#fff'};
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  z-index: 2;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  cursor: pointer;



  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${props => props.open ? '#111' : '#fff'};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`


// export default class Navbar extends React.Component {

// };

const Navbar = ({ menuLinks, logoAlt }) => {

  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation>
      <Logo alternative={logoAlt} />
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => {
          setNavbarOpen(!navbarOpen)
        }}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavMenu>
            {menuLinks.map(link => (
              <NavItem
                key={link.name}
              >
                <Link to={link.link}>
                  {link.name}
                </Link>
              </NavItem>
            ))}
          </NavMenu>
        </Navbox>
      ) : (
        <Navbox open>
          <NavMenu>
            {menuLinks.map(link => (
              <NavItem
                key={link.name}
              >
                <Link to={link.link}>
                  {link.name}
                </Link>
              </NavItem>
            ))} 
          </NavMenu>
        </Navbox>
      )}
    </Navigation>
  )
}

export default Navbar

// Navbar.propTypes = {
//   menuLinks: PropTypes.string,
// }
