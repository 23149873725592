import ColorBar from "./colorbar"
import PropTypes from "prop-types"
import React from "react"

import FooterImageMobile from "../assets/images/footer-mobile.svg"
import FooterImageDesktop from "../assets/images/footer-desktop.svg"

const Footer = ({ graphicEnabled, separatorEnabled }) => {
  return (
    <footer>
      {graphicEnabled ? (
        <picture>
          <source media="(min-width: 650px)" srcSet={FooterImageDesktop} />
          {/* <source media="(min-width: 465px)" srcset="img_white_flower.jpg" /> */}
          <img
            src={FooterImageMobile}
            alt="Northern Arizona Illustration"
            style={{ display: "block", width: "100%", height: "auto" }}
          />
        </picture>
      ) : null}

      <ColorBar
        bgColor="#7E7B7B"
        separatorEnabled={separatorEnabled}
        textColor="#fff"
      >
        <p className="text--footer">
          &copy;{new Date().getFullYear()} Northern Arizona Technology Alliance.
          All rights reserved.
        </p>
        <p className="text--footer">
          Digital presence provided by{" "}
          <a href="https://eightfold.io">Eightfold</a>
        </p>
      </ColorBar>
    </footer>
  )
}

Footer.propTypes = {
  graphicEnabled: PropTypes.bool,
  separatorEnabled: PropTypes.bool,
}

Footer.defaultProps = {
  graphicEnabled: true,
  separatorEnabled: true,
}

export default Footer
